import { PAGE_SERVICE_V3_BASE_URL } from 'config';
import useAjax from 'hooks/use-ajax';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPrimaryToken } from 'services/auth';

export interface IUsePagesV3CollectionOptions {
  blockRequest?: boolean;
  includeArchived?: number;
  limit?: number;
  pageOffset?: number;
  refreshKey?: string;
  search?: string;
  searchKey?: string,
  sortBy?: string;
  sortDirection?: string;
}

export interface IResponse<T> {
  data: {
    results: T[],
  } | null;
  error: object | null;
  loaded: boolean;
}

const usePagesV3Collection = <T = any>(options: IUsePagesV3CollectionOptions): Readonly<[T[], object | null, boolean]> => {
  const [pageResults, setResults] = useState<T[]>([]);
  const [ajaxError, setError] = useState<object | null>({});
  const [dataLoaded, setLoaded] = useState(false);

  const {
    limit = 1000,
    includeArchived = 0,
    pageOffset = 0,
    refreshKey,
    search = '',
    searchKey = '',
    sortBy = 'last_modified',
    sortDirection = 'desc',
    blockRequest,
  } = options;

  const token = useSelector(getPrimaryToken);
  const cacheInvalidation = useMemo(
    () => {
      return Date.now();
    },
    [refreshKey],
  );
  const url = `${PAGE_SERVICE_V3_BASE_URL}/pages?_=${cacheInvalidation}`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const params = {
    includeArchived,
    limit: limit.toString(),
    search,
    searchKey,
    skip: (limit * pageOffset).toString(),
    sortBy,
    sortDirection,
  };

  const pageState: IResponse<T> = useAjax({
    headers,
    params,
    url,
    blockRequest,
  });

  const { data, loaded, error } = pageState as IResponse<T>;

  useEffect(() => {
    if (loaded) {
      if (data) setResults(data.results);
    }
    setError(error);
    setLoaded(loaded);
  }, [data, loaded, error]);

  return [pageResults, ajaxError, dataLoaded] as const;
};

export default usePagesV3Collection;
