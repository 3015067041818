import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import IObject, { PageType } from 'models/IObject';
import useLegacyCollection from 'hooks/use-legacy-collection';
import usePagesV3Collection from 'hooks/use-pages-v3-collection';
import { getRefreshKey } from 'services/admin/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { createChannelOrPage } from 'services/admin/actions';
import { createObjectId } from 'utils';
import { getSiteId } from 'services/app/selectors';
import { getPath } from 'services/app-router/selectors/common';
import useTrackAdminNavigation from 'hooks/use-track-admin-navigation';
import { defaultGateData } from 'models/IGate';
import { CLASSIC_THEMES } from 'services/themes/constants';
import type { DeepPartial } from 'shared/type-utils';
import { THEME_TYPES } from 'models/ITheme';
import { injectT, useAdminTranslation } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import { pageTypeToNavigationChildType, pageTypeToNavigationParentType } from 'models/INavigation';
import { getDefaultNavigation } from 'services/navigationv2';
import useFeatureGateEnabled, { Feature } from './use-feature-gate-enabled';

const EMPTY_PAGE_DOC = injectT(
  (t) => lazy(
    () => ({
      collection: 'pages',
      data: {
        gate: {
          ...defaultGateData,
        },
        regions: {
          navigation: {
            items: [],
            // we could benefit from using the satisfies keyword in typescript 4.9 if we ever update to avoid this
            state: 'on' as 'off' | 'on',
          },
        },
        theme: {
          classicThemeOptions: {
            accentPrimary: CLASSIC_THEMES[0].colors.accentPrimary,
            accentSecondary: CLASSIC_THEMES[0].colors.accentSecondary,
          },
          id: CLASSIC_THEMES[0]._id!,
          type: THEME_TYPES.CLASSIC,
        },
      },
      seo: {
        title: t('ADMIN_DEFAULT_PAGE_SEO_TITLE') as string,
      },
    }),
  ),
);

export default function usePageCreator(): [((type: PageType, navigationParentId?: string) => void) | null, string] {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const [newId, setNewId] = useState(createObjectId);
  const siteId = useSelector(getSiteId);
  const path = useSelector(getPath);
  const refreshKey = useSelector(getRefreshKey);
  const currentDefaultNavigation = useSelector(getDefaultNavigation);
  const isPagesV3Enabled = useFeatureGateEnabled({
    feature: Feature.PAGES_V3,
    type: 'feature',
  });
  const pageHook = isPagesV3Enabled ? usePagesV3Collection : useLegacyCollection;

  const [pages, pagesError, pagesLoaded] = pageHook<IObject>({
    collection: 'pages',
    includeArchived: 1,
    refreshKey,
    search: 'landing',
    searchKey: 'type',
  });
  const [channels, channelsError, channelsLoaded] = pageHook<IObject>({
    collection: 'pages',
    includeArchived: 1,
    refreshKey,
    search: 'channel',
    searchKey: 'type',
  });
  const trackAdminNavigation = useTrackAdminNavigation();

  const getPageNumber = (type: PageType) => {
    const pageType = type === PageType.LANDING ? 'page' : 'channel';
    const collection = [...(type === PageType.LANDING ? pages : channels)];
    let freeSlug = false;
    let pageNumber = 0;
    while (!freeSlug) {
      pageNumber++;
      freeSlug = !collection.some(page => page?.slug === `${pageType}-${pageNumber}`);
    }
    return pageNumber;
  };

  interface IAddNewPageOrChannelToNavigation {
    navigationParentId?: string;
    newPage: IObject;
    type: PageType;
  }

  const addNewPageOrChannelToDefaultNavigation = ({
    navigationParentId,
    newPage,
    type,
  }: IAddNewPageOrChannelToNavigation) => {
    const newNavigationItemBase = {
      id: newPage._id,
      name: newPage.data.name!,
      slug: newPage.slug,
    };

    const updatedDefaultNavigation = cloneDeep(currentDefaultNavigation);
    const newPageSlug = newNavigationItemBase.slug.toLowerCase();
    let newPath = `/${newPageSlug}`;

    if (navigationParentId) {
      const navigationParent = updatedDefaultNavigation.parents.find(parent => parent.id === navigationParentId);
      if (!navigationParent) return;

      navigationParent.children.unshift({
        ...newNavigationItemBase,
        type: pageTypeToNavigationChildType[type],
      });
      const parentSlug = navigationParent.slug.toLowerCase();
      newPath = `/${parentSlug}/${newPageSlug}`;
    } else {
      updatedDefaultNavigation.parents.unshift({
        ...newNavigationItemBase,
        type: pageTypeToNavigationParentType[type],
        children: [],
      });
    }
    dispatch(createChannelOrPage({
      newChannelOrPage: newPage as IObject,
      path: newPath,
      updatedDefaultNavigation,
    }));
  };


  const createNewPage = (
    !pagesError && pagesLoaded &&
    !channelsError && channelsLoaded
  ) ? (type: PageType, navigationParentId?: string) => {
    const pageNumber = getPageNumber(type);
    const slug = `${type === PageType.LANDING ? 'page' : 'channel'}-${pageNumber}`;
    const pageName = `${type === PageType.LANDING ? t('ADMIN_LABEL_PAGE') : t('ADMIN_LABEL_CHANNEL')} ${pageNumber}`;
    const freshPage: DeepPartial<IObject & ReturnType<typeof EMPTY_PAGE_DOC>> = {
      ...EMPTY_PAGE_DOC(t),
      _id: newId,
      data: {
        ...(EMPTY_PAGE_DOC(t).data || {}),
        name: pageName,
      },
      seo: {
        ...(EMPTY_PAGE_DOC(t).seo || {}),
        title: pageName,
      },
      siteId,
      slug,
      type,
    };
    if (type === PageType.CHANNEL) {
      if (freshPage.data) {
        // Disable channel select by default
        freshPage.data.regions = {
          ...freshPage.data.regions,
          ['channel-select']: {
            pages: [],
            state: 'off',
          },
        };

        // Set image as default offline content mode
        freshPage.data.content = {
          ...freshPage.data.content,
          offline: {
            ...freshPage.data.content?.offline,
            mode: 'image',
          },
        };
      }

    }
    trackAdminNavigation({
      menu: 'channel_select',
      element: 'createChannel',
    });
    addNewPageOrChannelToDefaultNavigation({
      navigationParentId,
      newPage: freshPage as IObject,
      type,
    });
  } : null;

  useEffect(() => {
    setNewId(createObjectId());
  }, [path]);

  // Creates new site's page/channel
  return [
    createNewPage,
    newId,
  ];
}
