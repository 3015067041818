import { useAjax } from 'hooks';
import { useEffect, useState } from 'react';
import { fontUrl } from 'services/app';

interface IFontsResponse {
  fonts: string[];
}

export interface IFonts {
  baseUrl: string;
  label: string;
  name: string;
  value: string;
}

const useFonts = () => {
  const [fonts, setFonts] = useState<IFonts[]>([]);

  const {
    data,
    error,
    loaded,
  } = useAjax<IFontsResponse>({
    method: 'GET',
    url: `${fontUrl}/config.json`,
  });


  useEffect(() => {
    if (error || !loaded || !data?.fonts) {
      return;
    }

    const genSelectorData = (font: string): IFonts => {
      const formattedString = font.replace(/-|_/g, ' ');
      return ({ baseUrl: `${fontUrl}/${font}`, label: formattedString, name: font, value: font });
    };

    setFonts(data.fonts.map(genSelectorData));
  }, [data, error, loaded]);

  return fonts;
};

export default useFonts;
